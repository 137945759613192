import { Component } from '@angular/core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Table of Limb Length Discrepancy in correction analysis.
*/
@Component({
	selector: 'lld-recap',
	templateUrl: './lld-recap.component.html'
})
export class LldRecapComponent extends BaseComponent {

	isCollapsed: boolean = true;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	/**
	* Get Limb Lenght Discrepancy measure of long leg.
	*/
	get longlegLLD(): string {
		return this.calculateLLD(this.eocMeasures.eoc_ap_fullLength, this.defCLMeasures.def_ap_fullLength_ctrlateral);
	}

	/**
	* Get Limb Lenght Discrepancy measure of femur.
	*/
	get femurLLD(): string {
		return this.calculateLLD(this.eocMeasures.eoc_ap_femurLength, this.defCLMeasures.def_ap_femurLength_ctrlateral);
	}

	/**
	* Get Limb Lenght Discrepancy measure of tibia.
	*/
	get tibiaLLD(): string {
		return this.calculateLLD(this.eocMeasures.eoc_ap_tibiaLength, this.defCLMeasures.def_ap_tibiaLength_ctrlateral);
	}

	/**
	* Get True Lenght measure.
	*/
	get trueLength(): number {
		return this.eocMeasures.eoc_ap_femurLength && this.eocMeasures.eoc_ap_tibiaLength ? this.eocMeasures.eoc_ap_femurLength + this.eocMeasures.eoc_ap_tibiaLength : null;
	}

	/**
	* Get True Lenght measure of Contralateral.
	*/
	get trueLengthCL(): number {
		return this.defCLMeasures.def_ap_femurLength_ctrlateral && this.defCLMeasures.def_ap_tibiaLength_ctrlateral ? this.defCLMeasures.def_ap_femurLength_ctrlateral + this.defCLMeasures.def_ap_tibiaLength_ctrlateral : null;
	}

	/**
	* Get True Lenght measure of Limb Lenght Discrepancy.
	*/
	get trueLengthLLD(): string {
		return this.calculateLLD(this.trueLength, this.trueLengthCL);
	}


	private calculateLLD(length: number, cl_length: number): string {
		if (length && cl_length) {
			const lld = length - cl_length;
			let sign = lld > 0 ? this.labels['LLD_RECAP_LONG'] : null;
			sign = lld < 0 ? this.labels['LLD_RECAP_SHORT'] : null;
			return sign ? `${Math.abs(lld)} ${sign}` : `${lld}`;
		}
		return null;
	}

}
