export const environment = {
	name: 'verification',
	production: true,
	insightKey: '48977294-3785-404d-8481-a3e779ab4a2b',
	domainPatientAPI: 'https://api-preprod21.orthonext.app/pat/v1',
	domainUserAPI: 'https://api-preprod21.orthonext.app/usr/v1',
	domainImageAPI: 'https://api-preprod21.orthonext.app/img/v1',
	domainRayAPI: 'https://api-preprod21.orthonext.app/ray/v1',
	domainTlhexAPI: 'https://api-preprod21.orthonext.app/tlhex/v1',
	domainIntegrationAPI: 'https://api-preprod21.orthonext.app/int/v1',
	sseAPI: 'https://api-preprod21.orthonext.app/sse/v1',
	cdnUrl: 'https://cdnpreprod21.orthonext.app/ver',
	b2cTenantName: 'orthonextb2cpreprod21',
	b2cTenantId: '3e8f01db-2f26-4513-ac95-86181ab138e1',
	b2cSignInPolicyId: 'B2C_1_OnlySignIN',
	b2cResetPasswordPolicyId: 'B2C_1_PasswordReset',
	b2cChangePasswordPolicyId: 'B2C_1A_PasswordChange',
	b2cClientId: 'fc4b5701-6d33-4eaa-9e12-f4d27e478a5c',
	b2cApplicationScope: 'openid profile https://orthonextb2cpreprod21.onmicrosoft.com/api/user_impersonation',
	appSSOutUriApi: 'https://api-preprod21.orthonext.app/services/appssouturi',
	idleTimeToEndSession: 1800,
	buildVer: 'OrthoNext-Ray FE VER21 CI-CD_24134.01',
	patplanSite: 'https://patpreprod21.orthonext.app/list/plans',
	planDetailsSite: 'https://patpreprod21.orthonext.app/plan/edit?planGuid=',
	legalTermsSite: 'https://requestpreprod21.orthonext.app/legal-terms',
	aboutSite: 'https://cdnpreprod21.orthonext.app/ver/about/about.html',
	guideSite: {
		baseUrl: 'https://cdnpreprod21.orthonext.app/ver/Guide',
		list: 'https://cdnpreprod21.orthonext.app/ver/Guide/guide_list.json'
	},
	helpSite: {
		baseUrl: 'https://cdnpreprod21.orthonext.app/ver/Help/NextRay20/html',
		fileNames: 'https://cdnpreprod21.orthonext.app/ver/Help/NextRay20/file_names.json'
	},
	eifuSite: 'https://ifu.orthofix.it',
	templatesSite: 'https://fitbonepreprod21.orthonext.app',
	tlhexRaySite: 'https://tlhexpreprod21.orthonext.app/hexray',
	rayModelVersion: '1.1',
	resetSubscriptionTimeout: 30000,
	cookieName: '_iub_cs-67063076'
};
